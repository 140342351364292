import styled from 'styled-components';

// export const SectionHeader = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 8px;
//   font-family: var(--font-mono);
//   margin-bottom: 3rem;
//   font-weight: 600;
//   > div {
//     font-size: 30px;
//   }
//   > div {
//     &:first-child {
//       color: var(--blue);
//     }
//   }
//   > hr {
//     width: 20rem;
//     border: solid 0.5px var(--blue);
//   }
// `;

// export const AboutContent = styled.div`
//   color: var(--slate);
//   font-size: 16px;
//   width: 30rem;
//   font-weight: 600;
// `;

export const AboutWrapper = styled.div`
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: flex-start;
  // padding-left: 40rem;
  // padding-top: 20rem;
`;

export const AboutTop = styled.div`
  background-color: var(--light-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 15rem 5rem 15rem;
`;

export const AboutTopLeft = styled.div`
  width: 25rem;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: 1.75rem;
  color: var(--matte-black);
  font-weight: 300;
`;

export const Name = styled.div`
  font-size: 7rem;
  color: var(--black);
  line-height: 0.9;
  margin-top: 1rem;
`;

export const AboutTopRight = styled.div`
  width: 20rem;
  color: var(--matte-black);
`;

export const Summary = styled.div`
  padding-left: 2rem;
  border-left: 1px solid var(--matte-black);
  padding-top: 2.25rem;
`;

export const Socials = styled.div`
  padding-left: 2rem;
  border-left: 1px solid var(--matte-black);
  padding-bottom: 1rem;
`;

export const AboutBottom = styled.div`
  background-color: var(--dark-grey);
  padding: 15rem 25rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
`;

export const InfoBlock = styled.div`
  ${({ textAlign = 'center' }) => `
    text-align: ${textAlign};
  `}
`;

export const InfoTitle = styled.div`
  color: var(--matte-black);
`;

export const InfoText = styled.div`
  color: var(--black);
  font-weight: 700;
`;

export const HeaderText = styled.div`
  font-size: 7rem;
  color: var(--black);
  line-height: 0.9;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const AboutBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5rem;
`;

export const Acheivements = styled.div`
  border-right: 1px solid var(--matte-black);
  padding-right: 2.5rem;
  margin-right: 2.5rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 1rem;
  padding-top: 6rem;
  width: 10rem;
`;

export const AcheivementBlock = styled.div``;

export const Icon = styled.div`
  color: var(--matte-black);
`;

export const AcheivementCount = styled.div`
  color: var(--black);
  font-weight: 700;
  font-size: 2rem;
`;

export const AcheivementTitle = styled.div`
  color: var(--black);
  font-weight: 700;
`;

export const Description = styled.div``;

export const FirstParagraph = styled.div`
  color: var(--black);
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: left;
`;

export const Paragraph = styled.div`
  color: var(--matte-black);
  margin-bottom: 2rem;
  text-align: left;
`;

export const ResumeButton = styled.button`
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--dark-grey);
  border: 1px solid var(--orange);
  cursor: pointer;
`;
