import React from 'react';
import {
  AboutBottom,
  HeaderText,
  AboutBody,
  Acheivements,
  Description,
  AcheivementBlock,
  Icon,
  AcheivementTitle,
  Paragraph,
  ResumeButton,
  ResumeBlock,
  SubHeader,
  RBlock,
  ExperienceBlock,
  ExperienceDate,
  ExperienceTitle,
  ExperiencePosition,
  ExperienceDescription,
  DotContainer,
  Dot,
  SkillBlock,
  SkillMetre,
  SkillTitle,
  Percentage,
} from './styles';

const ColoredDots = ({ percent, color }) => {
  // Validate percent to be between 0 and 100
  const validatedPercent = Math.min(100, Math.max(0, percent));

  const dots = Array.from({ length: 10 }, (_, index) =>
    index < validatedPercent / 10 ? color : 'var(--light-warm-grey)',
  );

  return (
    <DotContainer>
      {dots.map((paint, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Dot key={index} color={paint} />
      ))}
    </DotContainer>
  );
};

const Resume = () => {
  const openResume = () => {
    window.open(
      'https://drive.google.com/file/d/1lJQwZ35s2l8yHOxI0m0cM_oRrUEz-ban/view?usp=sharing',
      '_blank',
    );
  };

  return (
    <div id='resume'>
      <AboutBottom>
        <HeaderText>My Resume</HeaderText>
        <AboutBody>
          <Acheivements>
            <AcheivementBlock>
              <Icon>Travelling Icon</Icon>
              <AcheivementTitle>Travelling</AcheivementTitle>
            </AcheivementBlock>
            <AcheivementBlock>
              <Icon>Fitness Icon</Icon>
              <AcheivementTitle>Fitness/health</AcheivementTitle>
            </AcheivementBlock>
            <AcheivementBlock>
              <Icon>Gaming Icon</Icon>
              <AcheivementTitle>Gaming</AcheivementTitle>
            </AcheivementBlock>
          </Acheivements>
          <Description>
            <Paragraph>
              Talented UX designer with 7+ years of experience, seeking to elevate UI/UX at ABC.
              Inc. As Lead UI/UX Head completed project before dead line. ittis augue, id
              sollicitudin justo tristique ut. Nullam ex enim, euismod vel bibendum ultrices,
              fringilla vel eros. Donec euism od leo um, at pellentesque velituis a congue sem.
            </Paragraph>

            <SkillBlock>
              <SubHeader>Skills</SubHeader>
              <SkillMetre>
                <SkillTitle>Photoshop</SkillTitle>
                <ColoredDots color='var(--dark-blue)' percent={90} />
                <Percentage color='var(--dark-blue)'>90 %</Percentage>
              </SkillMetre>
              <SkillMetre>
                <SkillTitle>Illustrator</SkillTitle>
                <ColoredDots color='var(--orange)' percent={80} />
                <Percentage color='var(--orange)'>80 %</Percentage>
              </SkillMetre>
              <SkillMetre>
                <SkillTitle>HTML</SkillTitle>
                <ColoredDots color='var(--blue)' percent={70} />
                <Percentage color='var(--blue)'>70 %</Percentage>
              </SkillMetre>
              <SkillMetre>
                <SkillTitle>CSS</SkillTitle>
                <ColoredDots color='var(--light-blue)' percent={40} />
                <Percentage color='var(--light-blue)'>40 %</Percentage>
              </SkillMetre>
              <SkillMetre>
                <SkillTitle>JavaScript</SkillTitle>
                <ColoredDots color='var(--light-brown)' percent={30} />
                <Percentage color='var(--light-brown)'>30 %</Percentage>
              </SkillMetre>
            </SkillBlock>

            <ResumeBlock>
              <div>
                <RBlock>
                  <SubHeader>Education</SubHeader>
                  <ExperienceBlock>
                    <ExperienceDate>2010 - 2012</ExperienceDate>
                    <ExperienceTitle>Master in Graphics</ExperienceTitle>
                    <ExperiencePosition>New York Universitiy</ExperiencePosition>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                  <ExperienceBlock>
                    <ExperienceDate>2010 - 2012</ExperienceDate>
                    <ExperienceTitle>Master in Graphics</ExperienceTitle>
                    <ExperiencePosition>New York Universitiy</ExperiencePosition>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                </RBlock>
                <RBlock>
                  <SubHeader>Expertise</SubHeader>
                  <ExperienceBlock>
                    <ExperienceDate>Logo Designing</ExperienceDate>
                    <ExperienceDate>Website Designing</ExperienceDate>
                    <ExperienceDate>Branding</ExperienceDate>
                    <ExperienceDate>Website Development</ExperienceDate>
                    <ExperienceDate>Character Designing</ExperienceDate>
                  </ExperienceBlock>
                </RBlock>
                <RBlock>
                  <SubHeader>Awards</SubHeader>
                  <ExperienceBlock>
                    <ExperienceDate>2015</ExperienceDate>
                    <ExperienceTitle>Art of the Week</ExperienceTitle>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                  <ExperienceBlock>
                    <ExperienceDate>2014</ExperienceDate>
                    <ExperienceTitle>Best Designer</ExperienceTitle>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                </RBlock>
              </div>
              <div>
                <RBlock>
                  <SubHeader>Experience</SubHeader>
                  <ExperienceBlock>
                    <ExperienceDate>2014 - Present</ExperienceDate>
                    <ExperienceTitle>Soft Tech Inc</ExperienceTitle>
                    <ExperiencePosition>UI Head & Manager</ExperiencePosition>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                  <ExperienceBlock>
                    <ExperienceDate>2010 - 2012</ExperienceDate>
                    <ExperienceTitle>Master in Graphics</ExperienceTitle>
                    <ExperiencePosition>New York Universitiy</ExperiencePosition>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                  <ExperienceBlock>
                    <ExperienceDate>2010 - 2012</ExperienceDate>
                    <ExperienceTitle>Master in Graphics</ExperienceTitle>
                    <ExperiencePosition>New York Universitiy</ExperiencePosition>
                    <ExperienceDescription>
                      Donec euism od leo um, at pellentesque velituis a congue sem
                    </ExperienceDescription>
                  </ExperienceBlock>
                </RBlock>
              </div>
            </ResumeBlock>

            <ResumeButton onClick={openResume}>Download Resume</ResumeButton>
          </Description>
        </AboutBody>
      </AboutBottom>
    </div>
  );
};

export default Resume;
