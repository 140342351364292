import React from 'react';
import {
  AboutWrapper,
  AboutTop,
  AboutBottom,
  AboutTopLeft,
  AboutTopRight,
  Title,
  Name,
  Summary,
  Socials,
  InfoWrapper,
  InfoBlock,
  InfoTitle,
  InfoText,
  HeaderText,
  AboutBody,
  Acheivements,
  Description,
  AcheivementBlock,
  Icon,
  AcheivementCount,
  AcheivementTitle,
  FirstParagraph,
  Paragraph,
  ResumeButton,
} from './styles';

function About() {
  const openResume = () => {
    window.open(
      'https://drive.google.com/file/d/1lJQwZ35s2l8yHOxI0m0cM_oRrUEz-ban/view?usp=sharing',
      '_blank',
    );
  };
  /**
   * @TODO: Update Icon, Social component to use SVGs
   */
  return (
    <AboutWrapper id='about'>
      <AboutTop>
        <AboutTopLeft>
          <Title>Fullstack Developer</Title>
          <Name>Adil Nawaz</Name>
        </AboutTopLeft>
        <AboutTopRight>
          <Summary>
            I am a UI/UX Designer based in New York and enjoy playing with colors. I love traveling,
            photography and music.
          </Summary>
          <Socials>Links </Socials>
        </AboutTopRight>
      </AboutTop>
      <AboutBottom>
        <InfoWrapper>
          <InfoBlock textAlign='left'>
            <InfoTitle>Born in</InfoTitle>
            <InfoText>Port Blair</InfoText>
          </InfoBlock>
          <InfoBlock textAlign='center'>
            <InfoTitle>Experience</InfoTitle>
            <InfoText>3+ Years</InfoText>
          </InfoBlock>
          <InfoBlock textAlign='right'>
            <InfoTitle>Date of Birth</InfoTitle>
            <InfoText>17 December 1998</InfoText>
          </InfoBlock>
        </InfoWrapper>
        <HeaderText>About Me</HeaderText>
        <AboutBody>
          <Acheivements>
            <AcheivementBlock>
              <Icon>Hi</Icon>
              <AcheivementCount>124</AcheivementCount>
              <AcheivementTitle>Successful Projects</AcheivementTitle>
            </AcheivementBlock>
            <AcheivementBlock>
              <Icon>Hi</Icon>
              <AcheivementCount>67</AcheivementCount>
              <AcheivementTitle>Happy Clients</AcheivementTitle>
            </AcheivementBlock>
            <AcheivementBlock>
              <Icon>Hi</Icon>
              <AcheivementCount>18</AcheivementCount>
              <AcheivementTitle>Awards Won</AcheivementTitle>
            </AcheivementBlock>
          </Acheivements>
          <Description>
            <FirstParagraph>
              I am a UI/UX Designer based in New York and enjoy playing with colors. I love
              traveling, photography and music. I am a UI/UX Designer based in New York and enjoy
              playing with colors. I love traveling, photography and music. I am a UI/UX Designer
              based in New York and enjoy playing with colors. I love traveling, photography and
              music. I am a UI/UX.
            </FirstParagraph>
            <Paragraph>
              I am a UI/UX Designer based in New York and enjoy playing with colors. I love
              traveling, photography and music. I am a UI/UX Designer based in New York and enjoy
              playing with colors. I love traveling, photography and music. I am a UI/UX Designer
              based in New York and enjoy playing with colors. I love traveling, photography and
              music. I am a UI/UX.
            </Paragraph>
            <Paragraph>
              I am a UI/UX Designer based in New York and enjoy playing with colors. I love
              traveling, photography and music. I am a UI/UX Designer based in New York and enjoy
              playing with colors. I love traveling, photography and music. I am a UI/UX Designer
              based in New York and enjoy playing with colors. I love traveling, photography and
              music. I am a UI/UX.
            </Paragraph>
            <ResumeButton onClick={openResume}>View Resume</ResumeButton>
          </Description>
        </AboutBody>
      </AboutBottom>
    </AboutWrapper>
  );
}

export default About;
