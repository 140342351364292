import styled from 'styled-components';

export const AboutBottom = styled.div`
  background-color: var(--light-grey);
  padding: 15rem 25rem;
`;

export const HeaderText = styled.div`
  font-size: 7rem;
  color: var(--black);
  line-height: 0.9;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const AboutBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5rem;
`;

export const Acheivements = styled.div`
  margin-right: 2.5rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 5rem;
  width: 10rem;
`;

export const AcheivementBlock = styled.div``;

export const Icon = styled.div`
  color: var(--matte-black);
`;

export const AcheivementTitle = styled.div`
  color: var(--black);
  font-weight: 700;
`;

export const Description = styled.div`
  padding-left: 2.5rem;
  border-left: 1px solid var(--matte-black);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
`;

export const Paragraph = styled.div`
  color: var(--matte-black);
  margin-bottom: 2rem;
  text-align: left;
`;

export const ResumeButton = styled.button`
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1rem;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  color: var(--white);
  cursor: pointer;
`;

export const SkillBlock = styled.div`
  margin-bottom: 5rem;
`;

export const SkillMetre = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SkillTitle = styled.div`
  color: var(--black);
  font-weight: 700;
  width: 12rem;
`;

export const Percentage = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 20px;
  background-color: ${(props) => props.color || 'lightgray'};
  color: var(--white);
  font-weight: 700;
  font-size: 1rem;
`;

export const DotContainer = styled.div`
  display: flex;
`;

export const Dot = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: ${(props) => props.color || 'black'};
  margin: 0 6px;
`;

export const ResumeBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  gap: 5rem;
`;
export const RBlock = styled.div`
  margin-bottom: 5rem;
  width: 20rem;
`;

export const SubHeader = styled.div`
  color: var(--black);
  font-weight: 800;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

export const ExperienceBlock = styled.div`
  margin-bottom: 2rem;
`;

export const ExperienceDate = styled.div`
  color: var(--matte-black);
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 0.25rem;
`;

export const ExperienceTitle = styled.div`
  color: var(--black);
  font-weight: 700;
  margin-bottom: 0.25rem;
`;

export const ExperiencePosition = styled.div`
  color: var(--matte-black);
`;

export const ExperienceDescription = styled.div`
  color: var(--matte-black);
  margin-top: 1rem;
`;
