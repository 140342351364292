import React, { useState } from 'react';
import logo from './icons/logo.svg';
import './App.css';
// import Navbar from './components/Navbar/Navbar';
import About from './components/About';
import Resume from './components/Resume';
// import Projects from './components/Projects';
// import Blogs from './components/Blogs';
// import Contacts from './components/Contacts';

function App() {
  const [hideNav, toggleNav] = useState(false);
  const onWheel = (e) => {
    const shouldHideNave = e?.deltaY > 0;

    if (shouldHideNave !== hideNav) {
      toggleNav(shouldHideNave);
    }
  };

  return (
    <div onWheel={onWheel}>
      {/* <Navbar /> */}
      <About />
      <Resume />
      {/* <Projects />
      <Blogs />
      <Testimony />
      <Contacts /> */}
      <div className='App'>
        <header className='App-header'>
          <img src={logo} className='App-logo' alt='logo' />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className='App-link'
            href='https://reactjs.org'
            target='_blank'
            rel='noopener noreferrer'
          >
            Learn React
          </a>
        </header>
      </div>
    </div>
  );
}

export default App;
